import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useAuthSignup from "hooks/useAuthSignup";
import { fetch as router } from "helpers/router";
import Error from "../InputGroupError";
import validationSchema from "./validation";

const applyServerErrors = (errors, handler, map) => {
  errors.forEach((error) => handler(map[error.key], { message: error.message }));
};

const SignupForm = () => {
  const { signup, location, errors: serverError } = useAuthSignup();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  if (location !== null) {
    window.location.href = location;
  }

  useEffect(() => {
    if (Object.keys(serverError).length === 0) {
      return;
    }
    applyServerErrors(serverError, setError, {
      "input.email": "email",
      "input.name": "name",
      "input.password": "password",
    });
  }, [serverError, setError]);

  const onSubmit = (data) => {
    signup(data.email, data.name, data.password);
  };
  return (
    <div className="form-row w-600">
      <h3 className="login-header">Sign up</h3>

      <p>Sign up to create a campaign or track your donations</p>

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form-row">
          <div className="form-group">
            <div className="form-col">
              <label htmlFor="signup_name">Name</label>
              <input {...register("name")} className="input-text" placeholder="Name" id="signup_name" />
              <Error name="name" errors={errors} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="form-col">
              <label htmlFor="signup_email">Email</label>
              <input {...register("email")} className="input-text" placeholder="Email" id="signup_email" />
              <Error name="email" errors={errors} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="form-col">
              <label htmlFor="signup_password">Password</label>
              <input
                {...register("password")}
                className="input-text"
                type="password"
                placeholder="Password"
                id="signup_password"
              />
              <Error name="password" errors={errors} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="form-col">
              <label htmlFor="signup_password_confirmation">Password</label>
              <input
                {...register("password_confirmation")}
                className="input-text"
                type="password"
                placeholder="Confirm Password"
                id="signup_password_confirmation"
              />
              <Error name="password_confirmation" errors={errors} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="form-col">
              <button
                id="signup-submit"
                type="submit"
                className="link-button --bold --primary --full"
                style={{ border: "0px solid #fff" }}
              >
                Sign me up!
              </button>
            </div>
          </div>
          <div className="form-group">
            <div className="form-col">
              <p>
                Already have an account?
                {" "}
                <Link to={router("auth")}>Log in</Link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
