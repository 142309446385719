import { useMutation } from "@apollo/client";
import RESET_PASSWORD from "./queries/resetPassword.gql";

const useAuthResetPassword = () => {
  const [resetPasswordMutation, { data, loading, error }] = useMutation(RESET_PASSWORD, { errorPolicy: "all" });

  const resetPassword = (email) => resetPasswordMutation({ variables: { email } });

  return {
    resetPassword,
    reset: (!loading && (data || error)),
    loading,
    error,
  };
};

export default useAuthResetPassword;
