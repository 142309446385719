const mapValidationErrors = (errors) => {
    if (errors.length === 0) {
        return [];
    }
    const validationError = errors.find((error) => error.message === 'validation');
    if (validationError === undefined || validationError.extensions === undefined) {
        return [];
    }
    return validationError.extensions.validation;
};
export default mapValidationErrors;
