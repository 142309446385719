import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
  password_confirmation: yup.string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default schema;
