import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useAuthLogin from "hooks/useAuthLogin";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { fetch as router } from "helpers/router";
import classNames from "classnames";
import Error from "../InputGroupError";
import validationSchema from "./validation";

const applyServerErrors = (errors, handler, map) => {
  errors.forEach((error) => handler(map[error.key], { message: error.message }));
};

const LoginForm = () => {
  const {
    login, location, errors: serverError, loading: isLoggingIn,
  } = useAuthLogin();
  const {
    register, handleSubmit, formState: { errors }, setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  if (location !== null) {
    window.location.assign(location);
  }

  useEffect(() => {
    if (Object.keys(serverError).length === 0) {
      return;
    }
    applyServerErrors(serverError, setError, {
      "input.email": "email",
      "input.password": "password",
    });
  }, [serverError, setError]);

  const onSubmit = (data) => {
    login(data.email, data.password);
  };
  return (
    <>
      <h3 data-test="login-header" className="login-header">Log in</h3>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="form-group">
          <label htmlFor="login_email">Email</label>
          <input {...register("email")} className="form-control" placeholder="Enter email" id="login_email" />
          <Error name="email" errors={errors} />
        </div>

        <div className="form-group">
          <label htmlFor="login_password">
            Password
            {" "}
            <Link to={router("auth.password.reset.index")} style={{ fontSize: "14px", fontWeight: "normal" }}>( Forgot your password? )</Link>
          </label>

          <input
            {...register("password")}
            type="password"
            name="password"
            className="form-control"
            id="login_password"
            placeholder="Enter password"
          />
          <Error name="password" errors={errors} />
        </div>
        <div className="form-group">
          <button
            id="login-submit"
            type="submit"
            disabled={isLoggingIn}
            className={classNames("link-button --bold --primary --full", { "--loading": isLoggingIn })}
            style={{ border: "0px solid #FFF" }}
          >
            Login
          </button>
        </div>

      </form>
      <div className="content-footer">
        <p>
          Don't have an account yet?
          {" "}
          <Link to={router("signup")}>Sign up</Link>
        </p>
      </div>
    </>
  );
};

export default LoginForm;
