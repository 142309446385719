import { useMutation } from "@apollo/client";
import mapValidationErrors from "helpers/graphqlErrors";
import { fetchAbsolute } from "helpers/router";
import LOGIN from "./queries/login.gql";

const useAuthLogin = () => {
  const [login, { data, loading, error }] = useMutation(LOGIN, { errorPolicy: "all" });

  let location = null;
  let errors = {};

  if (!loading && data && data.hasOwnProperty("login")) {
    if (data.login !== null) {
      location = data.login.intended ?? fetchAbsolute("web.dashboard");
    }
  }

  if (!loading && error && error.graphQLErrors) {
    errors = mapValidationErrors(error.graphQLErrors);
  }

  return {
    login: (email, password) => {
      login({ variables: { email, password } });
    },
    location,
    errors,
    loading,
  };
};

export default useAuthLogin;
