import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useAuthResetPassword from "hooks/useAuthResetPassword";
import validationSchema from "./validation";
import Error from "../InputGroupError";

const PasswordResetForm = () => {
  const { resetPassword, reset, loading } = useAuthResetPassword();
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    resetPassword(data.email);
  };

  return (
    <>
      <h3 className="login-header">Reset your password</h3>
      <p>Enter your email address and we'll email you a link to reset your password.</p>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>

        <div className="form-group">
          <input {...register("email")} id="email" required="required" type="email" className="form-control" name="email" placeholder="Email" />
          <Error name="email" errors={errors} />
        </div>

        <div className="form-group">
          <button type="submit" disabled={loading} className="link-button --bold --primary">
            Send Password Reset Link
          </button>
        </div>
        {reset && <p className="text-center">If your account exists, an email has been sent to you to change your password.</p>}
      </form>
    </>
  );
};

export default PasswordResetForm;
