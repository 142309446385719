import React from "react";
import { useRouteError } from "react-router-dom";

import "./style.scss";

const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className="error-page">
      <div className="content">
        <h3>Oops!</h3>
        <h4 className="mt-1">Something went wrong.</h4>
        <p className="error mt-1">
          {error.status === 404 ? "We can't find that page" : "We've encountered an error"}
        </p>
        <pre>
          {`${error?.message ?? ""}`}
        </pre>
      </div>
    </div>
  );
};

export default ErrorPage;
