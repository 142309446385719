import React from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "helpers/apolloClientLink";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { fetch as backendRouter } from "helpers/router";
import ErrorPage from "components/ErrorPage";
import LoginForm from "./components/LoginForm";
import SignupForm from "./components/SignupForm";
import PasswordResetForm from "./components/PasswordResetForm";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        path: backendRouter("auth"),
        element: <LoginForm />,
      },
      {
        path: backendRouter("signup"),
        element: <SignupForm />,
      },
      {
        path: backendRouter("auth.password.reset.index"),
        element: <PasswordResetForm />,
      },
    ],
  },
]);

const Auth = () => (
  <ApolloProvider client={client}>
    <div className="col-sm-6 col-sm-offset-3 login">
      <div className="content">
        <RouterProvider router={router} />
      </div>
    </div>
  </ApolloProvider>
);

export default Auth;
