import { useMutation } from "@apollo/client";
import { fetch as router } from "helpers/router";
import mapValidationErrors from "helpers/graphqlErrors";
import SIGNUP from "./queries/signup.gql";

const useAuthSignup = () => {
  const [signup, { data, loading, error }] = useMutation(SIGNUP, { errorPolicy: "all" });

  let location = null;
  let errors = {};

  if (!loading && data && data.hasOwnProperty("signup")) {
    if (data.login !== null) {
      location = router("web.dashboard");
    }
  }

  if (!loading && error && error.graphQLErrors) {
    errors = mapValidationErrors(error.graphQLErrors);
    location = null;
  }

  return {
    signup: (email, name, password) => {
      signup({ variables: { email, name, password } });
    },
    location,
    errors,
  };
};

export default useAuthSignup;
